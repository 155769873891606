@import '~src/scss/index.scss';
$sizes: (12, 14, 16, 20, 24, 32, 40, 48);


.icon {
  vertical-align: text-bottom;
}

@include mobile {
  @each $size in $sizes {

    .size-#{$size} {
      flex: none;
      width: safe($size);
      height: safe($size);
    }
  }
}

@include desktop {
  @each $size in $sizes {

    .size-#{$size} {
      width: #{$size}rem;
      height: #{$size}rem;
    }
  }
}
