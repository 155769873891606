@import '~src/scss/index.scss';
.userLink {
  display: flex;
  align-items: center;

  .field {
    display: flex;
    align-items: center;
    color: $dark-100;
    opacity: 0.6;
  }

  &:hover {

    .field {
      color: $brand-50;
      opacity: 1;
    }
  }
}
