@import '~src/scss/index.scss';
$raffle-img-size: 118rem;

.container {
  position: relative;
  width: 318rem;
  min-width: 318rem;
  height: 380rem;
  overflow: hidden;
  border-radius: 16rem;
}


.raffleImage {
  position: absolute;
  right: 16rem;
  bottom: 16rem;
  z-index: 2;
  width: $raffle-img-size;
  height: $raffle-img-size;
  overflow: hidden;
  border: 1.5rem solid rgba(255, 255, 255, 0.5);
  border-radius: 18rem;
  transition: width 0.2s, height 0.2s;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.media {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bar {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 67rem;
  padding: 16rem;
  color: $white-100;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 104.29%);
  border-radius: 0 0 16rem 16rem;
  backdrop-filter: blur(30rem);
}

.title {
  max-width: calc(100% - $raffle-img-size - 4rem);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.unset {
  text-transform: unset;
}

.banner {
  position: absolute;
  top: 16rem;
  left: 16rem;
  z-index: 2;
  padding: 6rem 12rem;
  color: $white-100;
  background-color: $brand-50;
  border: 1rem solid $white-100;
  border-radius: 24rem;
  backdrop-filter: blur(30rem);

  &.scheduled {
    color: $dark-100;
    background: rgba($white-100, 0.6);
    border: 1rem solid transparent;
  }

  &.ending {
    background: $gradient;
  }

  &.ended {
    background: rgba($gray-70, 0.6);
    border: 1rem solid transparent;
  }
}

.progress {
  position: absolute;
  bottom: 0;
  z-index: 3;
  width: 100%;
  height: 4rem;

  > div {
    height: 100%;
    background-color: $brand-50;
  }
}

@include mobile {

  .container {
    width: 280rem;
    min-width: 280rem;
  }

  .fullWidthOnMobile {
    width: 100%;
  }
}
