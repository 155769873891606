@import '~src/scss/index.scss';
.warning {
  position: fixed;
  right: 48rem;
  bottom: 48rem;
  z-index: 10;
  max-width: 432rem;
  padding: 16rem;
  color: $white-100;
  background-color: rgba($brand-50, .9);
  border-radius: 16rem;
  backdrop-filter: blur(4rem);
}

@include mobile {

  .warning {
    top: unset;
    right: 8rem;
    bottom: 8rem;
    width: calc(100% - 16rem);
  }
}
