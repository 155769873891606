@import '~src/scss/index.scss';
.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'PP Fragment', sans-serif;
  white-space: nowrap;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none !important;
  vertical-align: top;
  background: none;
  border: 0;
  cursor: pointer;
  transition: all 0.25s $ease-out-quadratic;
  user-select: none;
  width: fit-content;
  @include keyboard-focus;

  &:not(.disabled) {
    @media (hover: hover) {

      &:hover,
      &:focus {
        transition: all 0s $ease-out-quadratic;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    transition: none;
  }
}

.content {
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


// Colors
.primary {
  color: $white-100;
  background-color: $brand-50;
  @media (hover: hover) {


    &::before {
      z-index: 2;
      background-color: rgba(#fff, 0.2);
    }

    &::after {
      z-index: 1;
      filter: blur(8rem);
    }

    &:not(.disabled):not(:active) {

      &:hover {
        background-color: $brand-70;
      }
    }
  }

  &.disabled {
    color: $gray-20;
    background-color: $gray-10;
  }

  &:not(.disabled):active {
    background-color: $brand-20;
  }
}

.secondary {
  color: $white-100;
  background-color: $gray-90;

  &:not(.disabled) {
    @media (hover: hover) {

      &:hover {
        background-color: $gray-70;
      }
    }
  }

  &.disabled {
    color: $gray-20;
    background-color: $gray-10;
  }

  &:not(.disabled):active {
    background-color: $gray-50;
  }
}

.tertiary {
  color: $gray-90;

  &:not(.disabled) {

    &:hover {
      color: $brand-50;
    }
  }

  &.disabled {
    color: $gray-20;
  }

  &:not(.disabled):active {
    color: $brand-70;
  }
}

.stroked {
  color: $gray-90;
  border: 1rem solid $dark-100;

  &.size-32 {
    border-color: $gray-20;
  }

  &.size-40 {
    border-color: $gray-20;
  }

  &:not(.disabled) {

    &:hover {
      color: $brand-50;
      border: 1rem solid $brand-50;
    }
  }

  &.disabled {
    color: $gray-20;
    border: 1rem solid $gray-20;
  }

  &:not(.disabled):active {
    color: $brand-70;
    border: 1rem solid $brand-70;
  }
}


.action {
  color: $gray-90;
  background-color: $white-100;

  &:not(.disabled) {

    &:hover {
      color: $brand-50;
    }
  }

  &.disabled {
    color: $gray-20;
    background-color: $gray-10;
  }

  &:not(.disabled):active {
    color: $gray-90;
    background-color: $gray-10;
  }
}

.size-32 {
  height: 32rem;
  padding: 0 16rem;
  font-size: 12rem;
  border-radius: 12px;
}

.size-40 {
  min-width: 204rem;
  height: 40rem;
  padding: 0 16rem;
  font-size: 12rem;
  border-radius: 12rem;
}

.size-64 {
  min-width: 240rem;
  height: 64rem;
  padding: 0 20rem;
  border-radius: 16rem;
}

.widthAuto {
  min-width: auto;
}

@include mobile {

  .fullWidthOnMobile {
    width: 100%;
  }
}
