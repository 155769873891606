@import '~src/scss/index.scss';
.notifications {
  position: fixed;
  top: 88rem;
  z-index: $layer-modal + 1;
  gap: 16rem;

  a {
    color: $brand-50;
    text-decoration: underline;
  }
}


@include mobile {

  .notifications {
    right: 8rem;
    left: 8rem;
  }
}

@include desktop {

  .notifications {
    right: 48rem;
  }
}
