@import '~src/scss/index.scss';
.logo {
  font-size: 24rem;
  font-family: 'PP Fragment';
  letter-spacing: 0.03em;
  text-transform: uppercase;
}


@include mobile {

  .text {
    display: none;
  }
}

@include desktop {

  .text {
    height: 16rem;
    margin-left: 6rem;
  }
}
