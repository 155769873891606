@import '~src/scss/index.scss';
.url {
  display: flex;
  align-items: center;
  padding: 4rem 8rem;
  color: $gray-40;
  background-color: $gray-10;
  border-radius: 8rem;
  @include basic-transition();

  &:hover {
    color: $gray-90;
  }
}
