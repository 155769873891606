@import '~src/scss/index.scss';
.item {
  color: $gray-20;
  white-space: nowrap;
  text-transform: uppercase;
  @include basic-transition();
}

@include mobile {

  .navbar {
    flex-direction: column;
    width: 100%;
    margin-top: 36rem;
    padding-left: 32rem;
  }

  .item {
    width: fit-content;
    margin-top: 42rem;

    &:first-child {
      margin-top: 0;
    }
  }
}

@include desktop {

  .item {
    align-items: center;
    height: 32rem;
    margin-left: 20rem;
    padding: 6rem 12rem;
    color: $dark-100;
    border-radius: 8rem;

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      color: $brand-50;
    }

    &.active {
      display: flex;
      gap: 10rem;
      align-items: center;
      justify-content: center;
      color: $brand-50;
      background: $gray-10;
    }
  }
}
