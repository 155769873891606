@import '~src/scss/index.scss';
.button {
  display: flex;
  align-items: center;
  padding: 6rem 12rem;
  border-radius: 12rem;

  &:hover {
    background-color: #f5f5f5;
  }
}
