@import '~src/scss/index.scss';
.pageLayout {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background: $white-100;
}

.content {
  flex-grow: 1;
}
