@import '~src/scss/index.scss';
.container {
  position: relative;
  display: flex;
  align-items: center;
  @include unselectable-text();
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32rem;
  height: 32rem;
  border-radius: 8rem;
  cursor: pointer;

  &:hover {
    background-color: $gray-10;
  }
}

.arrowLeft {
  transform: rotate(90deg);
}

.arrowRight {
  transform: rotate(270deg);
}

.button {
  margin: 4rem;
  padding: 6rem 12rem;
  background-color: $gray-10;
  border-radius: 20rem 20rem 8rem 8rem;
  cursor: pointer;

  &.active {
    color: $white-100;
    background-color: $brand-50;
  }
}

@include mobile {

  .container {
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .container::-webkit-scrollbar {
    display: none;
  }
}
