@import '~src/scss/index.scss';
.tooltip {
  position: absolute;
  z-index: $layer-modal + 1;
  display: none;
  padding: 16rem 20rem;
  color: white;
  font-size: 12rem !important;
  background: rgba(18, 18, 18, 0.8);
  border-radius: 16rem;
  box-shadow: 0 69rem 28rem rgba(0, 0, 0, 0.04), 0 39rem 23rem rgba(0, 0, 0, 0.13), 0 17rem 17rem rgba(0, 0, 0, 0.21), 0 4rem 10rem rgba(0, 0, 0, 0.25), 0 0 0 rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(4rem);

  &.visible {
    display: block;
  }

  .title {
    font-weight: 800;
    line-height: 125%;
    text-transform: uppercase;
    opacity: 0.6;
  }

  a {
    text-decoration: underline;
  }
}

.arrow {
  position: absolute;
  top: 100%;

  :global(.under) & {
    transform: rotate(180deg);
  }
}

@include desktop {

  .arrow {
    position: absolute;
    top: 100%;
    right: calc(50% - 8rem);

    :global(.under) & {
      top: -8rem;
    }
  }

  .tooltip {
    max-width: 461rem;
  }
}

@include mobile {

  .arrow {
    position: absolute;
    top: 100%;

    :global(.under) & {
      top: -5rem;
    }
  }
}
