@import '~src/scss/index.scss';
@supports (will-change: transform) {
  @keyframes openModal {

    0% {
      transform: translateY(50%);
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.container {
  animation: openModal 0.35s ease;
  will-change: transform; // this fixes high CPU usage for blurred animation
}

.plainModal {
  position: relative;
  background-color: $white-100;

  &,
  &:focus {
    outline: none;
  }
}

.closeButton {
  position: absolute !important;
  top: 24rem;
  right: 24rem;
  z-index: 10;
  color: $gray-40;

   &:hover {
    color: $gray-50
   }
}


@include mobile {

  .container {
    position: absolute;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
  }

  .plainModal {
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
  }
}

@include desktop {

  .overlay {
    display: grid;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100%;
    margin: auto;
  }

  .plainModal {
    width: 100%;
    max-width: 416rem;
    padding: 40rem;
    overflow: hidden;
    border-radius: 16rem;
  }
}
