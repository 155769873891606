@import '~src/scss/index.scss';
.iconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray-20;
  cursor: pointer;
  @include basic-transition();

  &:hover {
    color: $gray-20;
  }
}
