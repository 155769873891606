@import '~src/scss/index.scss';
.container {
  position: relative;
  flex-basis: 24%;
  width: 318rem;
  border-radius: 16rem;
  overflow: hidden;

  &:hover {

    .media {
      transform: scale(1.1);
    }

    .overlay {
      opacity: 1;
    }
  }
}

.media {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform .5s;
}

.mediaContainer {
  aspect-ratio: 1 / 1;
  overflow: hidden;
  border-radius: 16rem;
}

.overlay {
  position: absolute;
  top: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: 16rem;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
  border-radius: 16rem;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
}
