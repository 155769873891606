@import '~src/scss/index.scss';
.container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $layer-modal - 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $white-100;
  backdrop-filter: blur(12rem);
}

.topBar {
  display: flex;
  align-items: center;
  height: 64rem;
  margin-bottom: 12rem;
}

.divider {
  width: 100%;
  height: 1rem;
  margin: 32rem 0;
  background-color: $gray-10;
}

.closeBtn {
  margin-right: 10rem;
  margin-left: 2rem;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.item {
  margin-top: 12rem;
  padding: 6rem 12rem;
  color: $dark-100;
  border-radius: 8rem;
}

.active {
  color: $brand-50;
  background-color: $gray-10;
}
