@import '~src/scss/index.scss';
.avatar {
  overflow: hidden;
  border-radius: 999rem;
}

.inner {
  display: flex;
  align-items: center;
  border-radius: 999rem;
}

.image {
  display: block;
  overflow: hidden;
  border-radius: 999rem;

  .size-14 & {
    width: 14rem;
    height: 14rem;
  }

  .size-18 & {
    width: 18rem;
    height: 18rem;
  }

  .size-28 & {
    width: 28rem;
    height: 28rem;
  }

  .size-40 & {
    width: 40rem;
    height: 40rem;
  }

  .size-180 & {
    width: 180rem;
    height: 180rem;
  }
}
