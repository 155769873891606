@import '~src/scss/index.scss';
.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 16rem;
}

@include desktop {
  .modal {
    min-width: 596rem;
    padding: 46rem;
  }
  .buttons {
    gap: 16rem;
  }
}

@include mobile {
  .modal {
    width: 100%;
    max-width: 100%;
    padding: 24rem 0;
  }
  .buttons {
    gap: 8rem;
  }
}
