@import '~src/scss/index.scss';
.container {
  width: 245rem;
}

.account {
  color: $gray-50;
  background-color: $gray-10;
  border-radius: 12rem;
}

.divider {
  height: 1rem;
  background-color: $gray-20;
}

@include mobile {

  .container {
    width: 100%;
  }
}

.chainLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24rem;
  height: 24rem;
  margin-right: 12rem;
  background-color: $gray-10;
  border-radius: 100rem;
}

.copyIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30rem;
  height: 30rem;
  padding: 4rem;
  border-radius: 30rem;

  &:hover {
    background-color: $gray-10;
  }

  &:active {
    background-color: $gray-20;
  }
}

.settingsLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20rem;
  cursor: pointer;

  &:hover {

    .arrowLink {
      background-color: $gray-20;
    }
  }
}

.arrowLink {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32rem;
  height: 24rem;
  background-color: $gray-10;
  border-radius: 12rem;
}
