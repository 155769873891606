@import '~src/scss/index.scss';
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44rem;
  height: 44rem;
  color: $gray-90;
  border: 1rem solid $gray-20;
  border-radius: 12rem;
  @include basic-transition;

  &:hover {
    color: $brand-70;
    border: 1rem solid $brand-70;
  }
}
