@import '~src/scss/index.scss';
.btn {
  height: 44rem;

  @include mobile {
    max-width: 150rem;
    min-width: 150rem;
  }
}

.container {

  [aria-label='Menu'] {
    width: 100%;
  }
}
