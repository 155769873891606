@import '~src/scss/index.scss';
.header {
  position: sticky;
  top: 0;
  z-index: $layer-header;
  background-color: #ffffffc1;
  backdrop-filter: blur(16rem);
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 72rem;
}

@include desktop {

  .header {
    min-height: 72rem;
  }
}


@include mobile {

  .header {
    min-height: unset;
  }

  .inner {
    min-height: unset;
    padding: 10rem 0;
  }
}
