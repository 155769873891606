@import '~src/scss/index.scss';
.menu {
  position: absolute;
  z-index: $layer-header - 2;
  width: fit-content;
  background: $white-100;
  border: 1rem solid #f5f5f5;
  border-radius: 16rem;
  box-shadow: 0 24rem 10rem rgba(0, 0, 0, 0.01), 0 13rem 8rem rgba(0, 0, 0, 0.03), 0 6rem 6rem rgba(0, 0, 0, 0.05), 0 1rem 3rem rgba(0, 0, 0, 0.05), 0 0 0 rgba(0, 0, 0, 0.06);
}

.btn {
  width: 100%;
}

.disable {
  pointer-events: none;
}

.control {
  color: $gray-20;
}

.opened {
  color: $white-100;
}

.content {
  width: fit-content;
  // max-height: calc(36rem * 8 + 16rem);
  padding: 8rem;
  overflow: auto;
}

.arrow {
  position: absolute;
  right: 16rem;
  bottom: 100%;
}

.bottomRight {
  top: calc(100% + 8rem);
  right: 0;

  .arrow {
    right: 16rem;
  }
}

.bottomLeft {
  top: calc(100% + 12rem);
  left: 0;

  .arrow {
    left: 16rem;
  }
}

.center {
  top: calc(100% + 12rem);
  left: 50%;
  transform: translate3d(-50%, 0, 0);

  .arrow {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
}

.arrowCenter {
  top: calc(100% + 12rem);
  right: 0;
  transform: translate3d(calc(50% - 8rem), 0, 0);

  .arrow {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
}
