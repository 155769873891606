@import '~src/scss/index.scss';
@font-face {
  font-weight: 500;
  font-family: 'JetBrains Mono';
  font-style: normal;
  src: url('./fonts/JetBrains/JetBrainsMono.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-weight: 400;
  font-family: 'VT323';
  font-style: normal;
  src: url('./fonts/VT323/VT323.ttf') format('truetype');
  font-display: swap;
}

.bg {
  background: #000;
  padding: 7rem 0 12rem;
  border-radius: 0 0 16rem 16rem;
  cursor: pointer;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 46rem;
  height: 48rem;
  overflow: hidden;
  margin: 0 auto 3rem;

  svg {
    height: 100%;
  }
}

.plus {
  height: 13rem;
}

.countdown {
  height: 20rem;
  font-family: 'JetBrains Mono';
  font-style: normal;
  font-weight: 500;
  font-size: 16rem;
  line-height: 20rem;
  color: $white-100;
  text-transform: uppercase;
}

.glich {
  height: 20rem;
  font-family: 'VT323';
  font-style: normal;
  font-weight: 500;
  font-size: 20rem;
  color: $white-100;
  text-transform: uppercase;
}
