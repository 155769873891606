@import '~src/scss/index.scss';
@include mobile {

  .footer {

    :global(.with-cart-button) & {
      margin-bottom: 64rem;
    }
  }

  .content {
    padding: 24rem 16rem 16rem;
  }
}

@include desktop {

  .content {
    display: flex;
    align-items: center;
    height: 64rem;
  }
}
