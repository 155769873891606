@import '~src/scss/index.scss';
.h1 {
  font-weight: 300;
  font-size: 64rem;
  font-family: 'PP Fragment Serif';
  line-height: 80rem;
  @include mobile {
    font-size: 40rem;
    line-height: 52rem;
  }
}

.h2 {
  font-weight: 300;
  font-size: 46rem;
  font-family: 'PP Fragment Serif';
  line-height: 60rem;
  @include mobile {
    font-size: 32rem;
    line-height: 40rem;
  }
}

.h3 {
  font-weight: 300;
  font-size: 32rem;
  font-family: 'PP Fragment Serif';
  line-height: 48rem;
  @include mobile {
    font-size: 28rem;
    line-height: 36rem;
  }
}

.h4 {
  font-weight: 300;
  font-size: 24rem;
  font-family: 'PP Fragment Serif';
  line-height: 32rem;
  @include mobile {
    font-size: 20rem;
    line-height: 28rem;
  }
}

.sub {
  font-weight: 400;
  font-size: 20rem;
  font-family: 'PP Fragment Sans';
  line-height: 32rem;
  @include mobile {
    font-size: 16rem;
    line-height: 24rem;
  }
}

.p1 {
  font-weight: 400;
  font-size: 18rem;
  font-family: 'PP Fragment Sans';
  line-height: 30rem;
  @include mobile {
    font-size: 16rem;
    line-height: 24rem;
  }
}

.p2 {
  font-weight: 400;
  font-size: 16rem;
  font-family: 'PP Fragment Sans';
  line-height: 24rem;
  @include mobile {
    font-size: 14rem;
    line-height: 20rem;
  }
}

.u1 {
  font-weight: 400;
  font-size: 16rem;
  font-family: 'PP Fragment Sans';
  line-height: 20rem;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  @include mobile {
    font-size: 14rem;
    line-height: 18rem;
  }
}

.u2 {
  font-weight: 400;
  font-size: 14rem;
  font-family: 'PP Fragment Sans';
  line-height: 18rem;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  @include mobile {
    font-size: 12rem;
    line-height: 16rem;
  }
}

.u3 {
  font-weight: 400;
  font-size: 12rem;
  font-family: 'PP Fragment Sans';
  line-height: 16rem;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

.c1 {
  font-weight: 400;
  font-size: 16rem;
  font-family: 'PP Fragment Sans';
  line-height: 20rem;
  @include mobile {
    font-size: 14rem;
    line-height: 18rem;
  }
}

.c2 {
  font-weight: 400;
  font-size: 14rem;
  font-family: 'PP Fragment Sans';
  line-height: 18rem;
  @include mobile {
    font-size: 12rem;
    line-height: 16rem;
  }
}

.c3 {
  font-weight: 400;
  font-size: 12rem;
  font-family: 'PP Fragment Sans';
  line-height: 16rem;
}

.gradient {
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.gradient-raffle {
  background-image: $gradient-raffle;
}
