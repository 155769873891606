@import '~src/scss/index.scss';
.container {
  display: flex;
  align-items: center;
  height: 44rem;
  padding: 8rem 12rem;
  color: $white-100;
  background-color: $gray-90;
  border-radius: 12rem;
  cursor: pointer;
  @include basic-transition;

  &:hover {
    background-color: $brand-90;
  }
}

.chain {
  text-align: left;
  text-transform: lowercase;
  opacity: 0.6;
}

.open {
  background-color: $brand-70 !important;
}

@include mobile {

  .container {
    padding: 8rem;
  }
}
